import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { GrCheckboxSelected } from "react-icons/gr";
import 'swiper/css';
import 'swiper/css/navigation';
import './Portfolio.css';

const Portfolio = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Hook para navegação
  const { email, language } = location.state || {};
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  const portfolioImages = [
    { src: '/images/cv1.png', alt: 'CV 1', modelId: '1y7_0cLZZPwFU3DDg0oaxIzezdPaWOEeqCkba5F4Mk0k' },
    { src: '/images/cv2.png', alt: 'CV 2', modelId: '1wOL5R-ZWqJ9wCoqTpKhIFwDgzVNlUJuFnpWa_BltnAA' },
    { src: '/images/cv3.png', alt: 'CV 3', modelId: '18ATzzY0KzJK-majweFDR9EowLCCp1gYeRiQw8SsOtTE' },
    { src: '/images/cv4.png', alt: 'CV 4', modelId: '1k7si-Kgq2qO00LzOjrHD3Ih8o4bKEGnvTX4QRVfkHOo' },
    { src: '/images/cv5.png', alt: 'CV 5', modelId: '1mMRjmvXBzoE_CXqDyhU4nSFFX4f4_YRyGuLJSNKdWIY' }
  ];
  

  const handleSelect = (image) => {
    navigate('/createcv', { state: { email, language, selectedImage: image.src, modelId: image.modelId } });
  };

  return (
    <div className="container">
      <div className="portfolio-title">
        <p>{t('choice_model')}</p>
        <p >{email}</p>
      </div>
      <Swiper spaceBetween={30} slidesPerView={1} navigation={true} modules={[Navigation]} className="swiper-container">
        {portfolioImages.map((image, index) => (
          <SwiperSlide key={index} className="swiper-slide">
            <div className="slide-content">
              <img src={image.src} alt={image.alt} className="portfolio-image" />
              <button className="select-button" onClick={() => handleSelect(image)}>
                <GrCheckboxSelected />
              </button>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Portfolio;
