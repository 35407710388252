import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; 
import axios from 'axios';
import pdfToText from 'react-pdftotext';
import './CvCreate.css';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';

const CVAnalyzer = () => {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');  // Mensagem de carregamento
  const [dragging, setDragging] = useState(false);
  const [googleDocLink, setGoogleDocLink] = useState(null); // Link do Google Docs
  const location = useLocation();
  const { selectedImage, modelId, email } = location.state || {};
  const { t, i18n } = useTranslation();
  const { language } = location.state || {};

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);



  const promptText = `
  Act as a proficient assistant with robust analytical skills. Learn the CV content presented below and respond with a JSON object containing the following details extracted from the image:

  - fullName: Full name of the person
  - phoneNumber: Phone number
  - address: Home address, without postal code
  - postalCode: Postal code of the address
  - jobTitle: Current or most recent job title
  - itSkills: Up to 5 relevant IT skills specifically related to software and tools usage, such as programming languages, software suites, or technical tools. Focus on those explicitly mentioned in the CV content.
  - interests: Personal interests
  - workExperience: List of work experiences, each containing:
      - title: Job title
      - company: Company name
      - location: Location of the job
      - startDate: Start date as follows:
          - If the date is only a year, keep it as "YYYY".
          - If the date includes month and year, format it as "MM/YYYY".
          - If the date includes day, month, and year, format it as "MM/YYYY".
      - endDate: End date as follows:
          - If the date is only a year, keep it as "YYYY".
          - If the date includes month and year, format it as "MM/YYYY".
          - If the endDate includes anything else or is an open-ended period, set it as "Présent".
      - description: Job description (optional)
  - education: List of educational qualifications, each containing:
      - degree: Degree obtained
      - institution: Name of the educational institution
      - startDate: Start date as follows:
          - If the date is only a year, keep it as "YYYY".
          - If the date includes month and year, format it as "MM/YYYY".
          - If the date includes day, month, and year, format it as "MM/YYYY".
      - endDate: End date as follows:
          - If the date is only a year, keep it as "YYYY".
          - If the date includes month and year, format it as "MM/YYYY".
          - If the endDate includes anything else or is an open-ended period, set it as "Présent".
  - languages: List of languages spoken with proficiency levels
  - skills: Up to 10 additional skills that are evident in the CV content. These should include both technical and soft skills that are clearly mentioned in the CV.
  - links: List of relevant links, each containing:
      - email: Email address
      - linkedin: LinkedIn profile URL
  - personalInfo: Personal information, containing:
      - dateOfBirth: Date of birth in format le day Month Anné (e.g., le 12 August 1990), in French, or the day Month Year (e.g., the 12th of August 1990), in English
      - drivingLicense: Type of driving license
      - residencePermit: Type of residence permit
      - nationality: Country of nationality
  - careerObjective: Based on the extracted text and in the same language, generate a career objective statement of approximately 45 words minimum and 50 words maximum, summarizing the individual's professional goals and aspirations in an appealing and persuasive manner.

  Example response:
  {
    "fullName": "John Doe",
    "phoneNumber": "+123456789",
    "address": "Main Street",
    "postalCode": "12345",
    "jobTitle": "Senior Software Engineer",
    "itSkills": "JavaScript, Python, React, Node.js",
    "interests": "Open source projects, Tech blogging",
    "workExperience": [
      {
        "title": "Senior Software Engineer",
        "company": "Tech Company",
        "location": "City, Country",
        "startDate": "2020-01",
        "endDate": "Present",
        "description": "Leading a team of developers to build scalable web applications."
      },
      {
        "title": "Software Engineer",
        "company": "Another Tech Company",
        "location": "City, Country",
        "startDate": "06/2018",
        "endDate": "12/2019",
        "description": "Developed and maintained web applications."
      }
    ],
    "education": [
      {
        "degree": "Bachelor of Science in Computer Science",
        "institution": "University of Somewhere",
        "startDate": "09/2014",
        "endDate": "06/2018"
      }
    ],
    "languages": [
      {
        "language": "English",
        "level": "Native"
      },
      {
        "language": "French",
        "level": "Intermediate"
      }
    ],
    "skills": ["Problem-solving", "Team leadership"],
    "links": [
      {
        "email": "john.doe@example.com",
        "linkedin": "https://www.linkedin.com/in/johndoe"
      }
    ],
    "personalInfo": {
      "dateOfBirth": "the 12th of August 1990",
      "drivingLicense": "A, B",
      "residencePermit": "Permanent",
      "nationality": "Portugal"
    },
    "careerObjective": "Based on the extracted text and in the same language, generate a career objective statement of approximately 45 words minimum and 50 words maximum, summarizing the individual's professional goals and aspirations in an appealing and persuasive manner."
  }

  You must respond STRICTLY with the structure in the example. NOTHING ELSE.
`;

// Manipulação de upload de PDF
const handlePDFUpload = (event) => {
  const file = event.target.files[0];
  setLoading(true);
  setLoadingMessage(t('extraindo_texto'));  // Exibindo "Extraindo o texto do PDF"

  pdfToText(file)
    .then((text) => {
      analyzeText(text); // Inicia a análise imediatamente após a extração do texto
    })
    .catch((error) => {
      alert('Erro ao extrair texto do PDF:', error);
      setLoading(false);
    });
};

// Análise de texto com a OpenAI
const analyzeText = async (extractedText) => {
  setLoadingMessage(t('curriculum_analise'));  // Exibindo "Analisando o texto com OpenAI"
  
  const apiKey = "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";

  try {
    const openAiResponse = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o',
        messages: [
          {
            role: 'user',
            content: `${promptText}\n\nCV Content:\n${extractedText}`,
          },
        ],
      },
      {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      }
    );

    const responseContent = openAiResponse.data.choices[0].message.content;

    if (!responseContent || responseContent.trim() === '') {
      alert('A resposta da OpenAI está vazia');
    } else {
      const validJsonString = extractJson(responseContent);
      const parsedData = JSON.parse(validJsonString);

      // Adiciona o modelId ao objeto antes de enviar ao Google Script
      parsedData.docId = modelId;
      parsedData.emailSend = email;
      sendToGoogleScript(parsedData);
    }

  } catch (error) {
    if (error.response) {
      alert(`Erro da API OpenAI: ${error.response.status}\n${JSON.stringify(error.response.data, null, 2)}`);
    } else if (error.request) {
      alert('Nenhuma resposta da API OpenAI. Verifique sua conexão.');
    } else {
      alert(`Erro: ${error.message}`);
    }
    setLoading(false);
  }
};

// // Função auxiliar para salvar dados em um arquivo de texto
// const saveDataToFile = (data) => {
//   // Cria um Blob com o conteúdo do JSON.stringify(data)
//   const blob = new Blob([data], { type: 'text/plain' });
  
//   // Cria um link temporário para o arquivo
//   const link = document.createElement('a');
//   link.href = URL.createObjectURL(blob);
  
//   // Define o nome do arquivo de texto
//   link.download = 'dados_enviados.txt';
  
//   // Adiciona o link temporariamente ao DOM e dispara o download
//   document.body.appendChild(link);
//   link.click();
  
//   // Remove o link do DOM após o download
//   document.body.removeChild(link);
// };

// Enviar dados para o Google Script
const sendToGoogleScript = async (data) => {
  setLoadingMessage(t('filling_cv'));  // Exibindo "Enviando dados para o Google Script"

  const url = 'https://script.google.com/macros/s/AKfycbyl-wrJux8y77zgz1AOJO0t6IWto_mT0yANGj7PS28VR8itassa4Rt3jB6JndBH3YC-iw/exec';

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain;charset=utf-8',
      },
      body: JSON.stringify(data),
      redirect: 'follow'
    });

    if (!response.ok) {
      const errorText = await response.text();
      alert('Erro HTTP! Status: ' + response.status + '\nErro: ' + errorText);
      throw new Error(`Erro HTTP! Status: ${response.status}`);
    }

    const responseData = await response.json();
    // Acessar o campo 'url' da resposta e definir como o link do Google Docs
    setGoogleDocLink(responseData.url);
    window.alert(t('mail_sended'));  // Exibir alerta de sucesso
    setLoading(false);  // Parar o carregamento após receber o link

  } catch (error) {
    alert('Erro ao enviar para o Google Script: ' + error.message);
    setLoading(false);
  }
};

const extractJson = (responseContent) => {
  const regex = /{[\s\S]*}/;
  const match = responseContent.match(regex);
  if (match) {
    return match[0];
  }
  throw new Error('Não foi possível encontrar um JSON válido na resposta');
};

// Função para simular o clique no input de arquivo
const triggerFileUpload = () => {
  document.getElementById('file-input').click();
};

return (
  <div className="container">
    <h2 className="cv-title">{t('analize_cv')}</h2>

    {selectedImage && (
      <img src={selectedImage} alt="Selected CV" className="selected-image" />
    )}

    {!googleDocLink && !loading && ( // Esconder a caixa de upload após o link ser gerado
      <div
        className={`dropzone ${dragging ? 'dragging' : ''}`}
        onDragOver={(e) => {
          e.preventDefault();
          setDragging(true);
        }}
        onDragLeave={() => setDragging(false)}
        onDrop={(e) => {
          e.preventDefault();
          setDragging(false);
          const file = e.dataTransfer.files[0];
          handlePDFUpload({ target: { files: [file] } });
        }}
      >
        <p>{t('drag_and_drop')}</p>
        <input
          type="file"
          id="file-input"
          accept="application/pdf"
          onChange={handlePDFUpload}
          className="file-input"
        />

        <button onClick={triggerFileUpload} className="upload-button">
          {t('select_the_PDF')}
        </button>
      </div>
    )}

    {loading && ( // Exibir o spinner enquanto a análise está em andamento
      <div className="spinner-container">
        <ClipLoader size={80} color={"#123abc"} loading={loading} />
        <p>{loadingMessage}</p>
      </div>
    )}

    {!loading && googleDocLink && ( // Exibir o link do Google Docs após a criação
      <div className="result-container">
        <h3>{t('google_docs_created')}</h3>
        <a
          href={googleDocLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => setGoogleDocLink(null)} // Após clicar, esconder o link e mostrar a caixa de upload novamente
        >
          {t('open_google_docs_document')}
        </a>
      </div>
    )}
  </div>
);
};

export default CVAnalyzer;
