import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

// Inicializa o i18next
i18n
  .use(HttpBackend) // Carregar recursos de tradução
  .use(initReactI18next) // Passa o i18next para o react-i18next
  .init({
    lng: 'fr', // Define o idioma inicial como francês
    fallbackLng: 'fr', // Defina o idioma padrão como francês
    debug: true,
    interpolation: {
      escapeValue: false, // React já faz a proteção contra XSS
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Caminho dos arquivos de tradução
    },
  });

export default i18n;
