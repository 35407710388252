import React from 'react';
import PropTypes from 'prop-types';
import './Button.css';

const Button = ({ onClick, label, Icon }) => {

  // Função de clique que chama a prop onClick recebida
  const handleClick = () => {
    console.log(`Botão ${label} clicado`); // Para depurar
    onClick(); // Chama a função passada para mudar o idioma
  };

  return (
    <button onClick={handleClick} className="language-button">
      <Icon className="language-icon" /> {/* Exibe o ícone do país */}
      <span className="language-label">{label}</span>
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired, // Garante que a função onClick seja passada
  label: PropTypes.string.isRequired, // Texto do botão
  Icon: PropTypes.elementType.isRequired, // Propriedade para o ícone
};

export default Button;
