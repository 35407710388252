import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Button from './components/Button/Button';
import EmailForm from './components/EmailForm/EmailForm';
import Portfolio from './components/Portfolio/Portfolio';
import { useTranslation } from 'react-i18next';
import { GiPortugal, GiFrance, GiItalia, GiSpain } from 'react-icons/gi';
import { FaFontAwesomeFlag } from 'react-icons/fa';
import CreateCV from './components/CvCreate/CvCreate';
import './App.css';

function App() {
  const { i18n, t } = useTranslation(); // Hook de tradução

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng).then(() => {
      // Sucesso ao mudar o idioma
    }).catch((error) => {
      alert(`Erro ao mudar o idioma para: ${lng}`, error);
    });
  };

  return (
    <Router>
      <Routes>
        {/* Página principal com o EmailForm e layout padrão */}
        <Route
          path="/"
          element={
            <div className="container">
              <div className="image-container"> {/* Contêiner para as imagens */}
                <img src="/images/cv.png" alt="CV" className="cv-image" />
                <img src="/images/cl.png" alt="CL" className="cv-image" />
              </div>
              <h1 className="title">{t('choose_language')}</h1> {/* Texto dinâmico */}
              <div className="language-buttons">
                <Button onClick={() => changeLanguage('fr')} label="FR" Icon={GiFrance} />
                <Button onClick={() => changeLanguage('de')} label="DE" Icon={FaFontAwesomeFlag} />
                <Button onClick={() => changeLanguage('it')} label="IT" Icon={GiItalia} />
                <Button onClick={() => changeLanguage('en')} label="EN" Icon={FaFontAwesomeFlag} />
                <Button onClick={() => changeLanguage('pt')} label="PT" Icon={GiPortugal} />
                <Button onClick={() => changeLanguage('sp')} label="ES" Icon={GiSpain} />
              </div>
              <EmailForm /> {/* O formulário de e-mail */}
            </div>
          }
        />

        {/* Rota separada para o Portfolio */}
        <Route path="/portfolio" element={<Portfolio />} /> {/* Portfolio exibido como página isolada */}
        
        {/* Nova rota para o CreateCV */}
        <Route path="/createcv" element={<CreateCV />} /> {/* CreateCV exibido como página isolada */}
      </Routes>
    </Router>
  );
}

export default App;


