import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './EmailForm.css';

const EmailForm = () => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setErrorMessage(t('email_required'));
    } else {
      setErrorMessage('');
      // Redireciona com o email e o idioma selecionado
      navigate('/portfolio', { state: { email, language: i18n.language } });
    }
  };

  return (
    <form className="email-form" onSubmit={handleSubmit}>
      <label htmlFor="email" className="email-label">{t('enter_email')}</label>
      <input
        type="email"
        id="email"
        className="email-input"
        placeholder={t('email_placeholder')}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <button type="submit" className="submit-button">{t('submit_button')}</button>
    </form>
  );
};

export default EmailForm;
